"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EmailValidator = exports.DomainValidator = undefined;

var _DomainValidator = require("./DomainValidator");

var _EmailValidator = require("./EmailValidator");

exports.DomainValidator = _DomainValidator.DomainValidator;
exports.EmailValidator = _EmailValidator.EmailValidator;